import React from 'react';

// Components
import {
  Card,
  CardGroup,
  Container,
  ContentBlock,
  HomeContent,
  HomeHero,
  Layout,
  NewsFeed,
  OrderProcess,
  ProductFeed,
  ServiceFeed,
} from '../components';

// Data
import data from '../data/pages/home.json';
import testimonials from '../data/testimonials.json';

const Home = () => {
  // const t = useTranslations(); //useIntl().formatMessage;
  const content = data.content;

  return (
    <>
      <Layout pageContext={data} isDarkTheme noHero>
        {/* Custom home page hero */}
        <HomeHero
          title={content.hero.title}
          mobileSubtitle={content.hero.mobileSubtitle}
          imagePath={content.hero.imagePath}
        />

        <Container addSpacers>
          {/* Hero Statement & Gold Chart */}
          <HomeContent
            subtitle={content.hero.subtitle}
            text={content.hero.text}
            cta={content.hero.cta}
          />

          {/* About Statement */}
          <ContentBlock
            title={content.statement.title}
            content={content.statement.content}
            cta={content.statement.cta}
            buttonProps={{ styleType: 'flat', showArrow: true }}
          />

          {/* Service List */}
          <ServiceFeed
            columnCount={3}
            scrollOnMobile={true}
            headerProps={{
              title: content.services.header.title,
              text: content.services.header.text,
            }}
          />

          {/* Product Feed */}
          <ProductFeed
            feedCount={4}
            columnCount={4}
            scrollOnMobile={true}
            headerProps={{
              title: 'Investment Products',
              text:
                'With dozens of products to choose from, you can make your precious metals collection both sensible and beautiful. Your collection does not need to be boring to be smart. Price fluctuates depending on market values, please reach out for quote.',
            }}
            cta={{label: 'All Investment Products', src: '/services/investment-products'}}
          />

          {/* Order Processing */}
          <OrderProcess />

          {/* News Feed */}
          <NewsFeed />
        </Container>
      </Layout>
    </>
  );
};

export default Home;
